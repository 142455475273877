import React, { useEffect, useRef, useState } from 'react';
import 'chart.js/auto';
import { Chart, registerables } from 'chart.js';
import { faker } from '@faker-js/faker';
import 'chartjs-plugin-datalabels';

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'

Chart.register(...registerables);

const GraphPie = () => {

  const totalTickets = 29853;

  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const [fakeData, setFakeData] = useState<{ labels: string[]; values: number[] }>({ labels: [], values: [] });
  const [percentageValues, setPercentageValues] = useState<number[]>([]);

  const [reportUserList, setReportUserList] = useState<any[]>([])

  const getAllEntrances = () => {



    fetch(`${BASE.GRAPHY.url}${BASE.GRAPHY.getEntrnaces}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: CONFIG.API_KEY
        }
    })
        .then((resg) => resg.json())
        .then((response) => {
          setFakeData({ labels: ['Falta', 'Entrou'], values: [(totalTickets-response[1]), response[1]] });

          const currentEntrancePercentage =  (response[1] / totalTickets) * 100;
          const percentages = [(100-currentEntrancePercentage), currentEntrancePercentage];
          setPercentageValues(percentages);
        })
        .catch((error) => {
            console.log(error)
        })
}


  useEffect(() => {
    const generateFakeData = () => {
      const newLabels: string[] = [];
      const newValues: number[] = [];

      let total = 0;

      for (let i = 0; i < 2; i++) {
        const productName = faker.commerce.productName();
        newLabels.push(productName);

        const productPrice = faker.datatype.number({ min: 1, max: 40000 });
        newValues.push(productPrice);

        total += productPrice;
      }

      // setFakeData({ labels: ['Entrou', 'Falta'], values: [1220, 1570] });

      // const percentages = newValues.map(value => (value / total) * 100);
      // setPercentageValues(percentages);
    };

    generateFakeData();
    // getAllEntrances();

  setInterval(
    getAllEntrances
    , 10000);
  }, []);

  useEffect(() => {
    const createChart = () => {
      if (chartRef.current) {
        const ctx = chartRef.current.getContext('2d');
        if (ctx) {
          return new Chart(ctx, {
            type: 'pie',
            data: {
              labels: fakeData.labels,
              datasets: [{
                data: fakeData.values,
                backgroundColor: [
                  'rgba(255, 255, 255)',
                  'rgba(255, 46, 51)',
                ],
                borderColor: [
                  'rgba(255, 255, 255)',
                  'rgba(255, 46, 51)',
                ],
                borderWidth: 1
              }]
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              plugins:{
                legend:{
                  display: false
                }
              }
            }
          });
        }
      }
      return null;
    };

    const chart = createChart();
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [fakeData]);

  return (
    <div className='h5 graphy-custom-label' style={{ position: 'relative', width: '375px', height: '375px' }}>
      <div style={{ position: 'absolute', top: '100px', left: '60px', width: '60px', height: '60px' }}>
        <div className='graphy-custom-label'>Entraram:</div>
        <div  className='graphy-custom-dataset'>
          <span>{fakeData.values[1]}</span>
          {percentageValues.length > 0 && `${percentageValues[1].toFixed(2)}%`}
        </div>
      </div>
      <div style={{ position: 'absolute', top: '100px', right: '70px', width: '60px', height: '60px' }}>
        <div className='graphy-custom-label'>Faltam:</div>
        <div className='graphy-custom-dataset'>
          <span>{fakeData.values[0]}</span>
          {percentageValues.length > 1 && `${percentageValues[0].toFixed(2)}%`}</div>
      </div>
      <canvas ref={chartRef}></canvas>
    </div>
  );  
};

export default GraphPie;




