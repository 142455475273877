import React, { useEffect, useRef, useState } from 'react';
import 'chart.js/auto';
import { Chart, registerables } from 'chart.js';
import { faker } from '@faker-js/faker';

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'


Chart.register(...registerables);

const GraphLine = () => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const [fakeData, setFakeData] = useState<{ labels: string[]; values: number[] }>({ labels: [], values: [] });


  const dateConvert = (date: any) => {
    const eventDate = new Date(date)

    const hours = eventDate.getUTCHours(); 
    const minutes = eventDate.getUTCMinutes().toString().padStart(2, '0'); 

    return `${hours}:${minutes}`; 
  }

  const getAccessGroup = () => {

    fetch(`${BASE.GRAPHY.url}${BASE.GRAPHY.getAccessByMinute}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: CONFIG.API_KEY
        }
    })
        .then((resg) => resg.json())
        .then((response) => {
          setFakeData(response)
          const newLabels: string[] = []; 
          const newValues: number[] = []; 
          for (const item of response) {

            newLabels.push(dateConvert(item.entrance_time));
  
            newValues.push(item.count);
          }
      
          setFakeData({ labels: newLabels, values: newValues });

        })
        .catch((error) => {
            console.log(error)
        })
}


  useEffect(() => {
    const generateFakeData = () => {
      const newLabels: string[] = []; 
      const newValues: number[] = []; 
  
      for (let i = 0; i < 150; i++) {
        const hour = Math.floor(Math.random() * 24).toString().padStart(2, '0');
        const minute = Math.floor(Math.random() * 60).toString().padStart(2, '0');
        
        const time = `${hour}:${minute}`;
        newLabels.push(time);
  
        const peopleCount = faker.datatype.number({ min: 1, max: 40000 });
        newValues.push(peopleCount);
      }
  
      // setFakeData({ labels: newLabels, values: newValues });
    };
    
    // getAccessGroup();
    generateFakeData();


    setInterval(
      getAccessGroup
      , 10000);

  }, []);
  
  useEffect(() => {
    const createChart = () => {
      if (chartRef.current) {
        const ctx = chartRef.current.getContext('2d');
        if (ctx) {
          return new Chart(ctx, {
            type: 'bar',
            data: {
              labels: fakeData.labels,
              datasets: [{
                label: 'Entradas',
                data: fakeData.values,
                backgroundColor: 'rgba(255, 46, 51, 0.2)',
                borderColor: 'rgba(255, 46, 51, 1)',
                borderWidth: 2
              }]
            },
            options: {
              maintainAspectRatio: false,
              scales:{
                x:{
                  grid:{
                    display:false
                  },
                  ticks: {
                    color: "#ffffff", // this here
                  },
                },
                y:{
                  grid:{
                    display:false
                  }
                }
              },
              plugins:{
                legend:{
                  display:false,
                }
              }
            }
          });
        }
      }
      return null;
    };

    const chart = createChart();
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [fakeData]);

  return (
    <div style={{ height: '200px' }}>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default GraphLine;

