import React, { useEffect, useState } from 'react';
import { faker } from '@faker-js/faker';

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'

interface DadosSetor {
  setor: string;
  pessoasEntraram: number;
  pessoasFaltam: number;
  total: number;
}



const Table1 = () => {
  const [dados, setDados] = useState<DadosSetor[]>([]);
  const totalTickets = 29853;


const getAllEntrances = () => {



  fetch(`${BASE.GRAPHY.url}${BASE.GRAPHY.getEntrnaces}`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          Authorization: CONFIG.API_KEY
      }
  })
      .then((resg) => resg.json())
      .then((response) => {

        const novosDados: DadosSetor[] = [];

        novosDados.push({ setor: 'FACIAL', pessoasEntraram: response[1] || 0, pessoasFaltam: (totalTickets - response[1]), total: totalTickets });
        novosDados.push({ setor: 'SUP Norte', pessoasEntraram: 0, pessoasFaltam: 0, total: 0 });
        novosDados.push({ setor: 'SUP LESTE', pessoasEntraram: 0, pessoasFaltam: 0, total: 0 });
        novosDados.push({ setor: 'CADEIRA NORTE', pessoasEntraram: 0, pessoasFaltam: 0, total: 0 });
        novosDados.push({ setor: 'CADEIRA LESTE', pessoasEntraram: 0, pessoasFaltam: 0, total: 0 });
        novosDados.push({ setor: 'CADEIRA SUDESTE', pessoasEntraram: 0, pessoasFaltam: 0, total: 0 });
        novosDados.push({ setor: 'ESPEC. INFERIOR', pessoasEntraram: 0, pessoasFaltam: 0, total: 0 });
        novosDados.push({ setor: 'ESPEC. SUPERIOR', pessoasEntraram: 0, pessoasFaltam: 0, total: 0 });
        novosDados.push({ setor: 'NAVE CWG', pessoasEntraram: 0, pessoasFaltam: 0, total: 0 });
        novosDados.push({ setor: 'LOUGE PREMIUM', pessoasEntraram: 0, pessoasFaltam: 0, total: 0 });
        novosDados.push({ setor: 'ESQUADRAO ZONE', pessoasEntraram: 0, pessoasFaltam: 0, total: 0 });
  
        setDados(novosDados);
        
      })
      .catch((error) => {
          console.log(error)
      })
}
  useEffect(() => {
    // const gerarDados = () => {

    // };

    // getAllEntrances();
    
    setInterval(
      getAllEntrances
      , 10000);

  }, []);


  const calcularTotalColuna = (propriedade: keyof DadosSetor): number => {
    return dados.reduce((total, item) => total + Number(item[propriedade]), 0);
  };

  return (
    <div>
      <table className='table table-bordered border-dark table-hover text-center game-table' style={{ fontSize: '0.6rem' }}>
        <thead>
          <tr>
            <th className='bg-dark bg-gradient text-white border-dark' style={{ width: '100px'}}>Setor</th>
            <th className='bg-dark bg-gradient text-white border-dark' style={{ width: '100px'}}>Entrou</th>
            <th className='bg-dark bg-gradient text-white border-dark' style={{ width: '100px'}}>Falta</th>
            <th className='bg-dark bg-gradient text-white border-dark' style={{ width: '100px'}}>Total</th>
          </tr>
        </thead>
        <tbody>
          {dados.map((item, index) => (
            <tr key={index}>
              <td className='text-white bg-success bg-gradient border-dark'>{item.setor}</td>
              <td className='text-white bg-success bg-gradient border-dark'>{item.pessoasEntraram}</td>
              <td className='text-white bg-success bg-gradient border-dark text-center'>{item.pessoasFaltam}</td>
              <td className='text-white bg-success bg-gradient border-dark text-center'>{item.total}</td>
            </tr>
          ))}

          <tr>
            <td className='bg-dark border-dark text-center'></td>
            <td className='bg-dark border-dark text-center'></td>
            <td className='bg-dark border-dark text-center'></td>
            <td className='bg-dark border-dark text-center'></td>
          </tr>

          <tr>
            <td className='bg-dark bg-gradient text-white border-dark text-center'>Total</td>
            <td className='bg-dark bg-gradient text-white border-dark text-center'>{calcularTotalColuna('pessoasEntraram')}</td>
            <td className='bg-dark bg-gradient text-white border-dark text-center'>{calcularTotalColuna('pessoasFaltam')}</td>
            <td className='bg-dark bg-gradient text-white border-dark text-center'>{calcularTotalColuna('total')}</td>
          </tr>
        </tbody>
      </table>
    </div>

  );
};

export default Table1;



